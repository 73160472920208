import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/common/Layout/layout"
import SEO from "../../components/common/SEO/seo"
import BannerPage from "../../components/banner/BannerPage/bannerPage"
import SectionTextImage from "../../components/section/TextImage/textImage"
import CallToAction from "../../components/section/CallToAction/callToAction"

const ReportajesFotograficosPage = () => {
    const data = useStaticQuery(graphql`
    query {
      reportajeFotograficoImage1: file(relativePath: { eq: "images/sections/productora-audiovisual/reportajes-fotograficos/reportajes-fotograficos-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Layout>
            <SEO
                title="Reportajes Fotográficos"
                description="Estudio fotográfico en Barcelona especializada en reportajes fotográficos publicitarios de espacios y ambientes."
            />

            <BannerPage
                title="Reportajes fotográficos publicitarios"
                text="Fotografía de espacios y ambientes"
                color="yellow"
            />

            <SectionTextImage
                title1="¡Enseña "
                title2="cómo eres!"
                text={<Fragment>
                    ¿Aún no tienes fotografías de tu espacio de trabajo? ¿De tu nave industrial? ¿Buscas tener un spot publicitario de tus productos? En IBX podemos <strong>fotografiar vuestro espacio y ambiente</strong> para así poder mostrar a los usuarios quiénes sóis con imágenes. <br /><br />
                    Somos <strong>expertos en reportajes de fotografía en el exterior</strong>. Captamos aquellas imágenes que el cliente necesita para vender sus productos o servicios.
                </Fragment>}
                image={data.reportajeFotograficoImage1.childImageSharp.fluid}
                imageAlt="Reportajes fotográficos Barcelona - Agencia Reportajes Fotrográficos"
                color="yellow"
                containerTextRight={false}
            />

            <CallToAction
                color="yellow"
                title="¡Creamos espacios y ambientes mediante la fotografía!"
                text={<Fragment>
                    ¡<strong>Reportajes fotográficos personalizados en Barcelona</strong>!<br />
                    ¿Necesitas un reportaje fotográfico?
                </Fragment>}
                button="¡LLámanos!"
                buttonUrl="/contacto"
            />

        </Layout>
    )

}
export default ReportajesFotograficosPage
